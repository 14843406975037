const INFO = {
	main: {
		title: "Yun Chen",
		name: "Yun Chen",
		email: "mail@yunchen.dev",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/yunchendev",
		github: "https://github.com/yunchendev",
		linkedin: "https://www.linkedin.com/in/yunchendev/",
		instagram: "https://instagram.com/yunchendev",
		stackoverflow: "https://stackoverflow.com/",
	},

	homepage: {
		title: "Software Developer, and ping pong fanatic.",
		description:
			"I am a full stack engineer. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "I’m Yun Chen",
		description:
			"I'm a software engineer for JPMorgan Chase in the NY metro area",
	},

	articles: {
		// 	title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		// 	description:
		// 		"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		// {
		// 	title: "Project 1",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 2",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 3",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 4",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 5",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },
	],
};

export default INFO;
